<template>
  <div>
    <b-card>
      <pocu-header></pocu-header>
      <b-row v-if="currentProject" style="margin-top:100px;">
        <b-col cols="12" sm="12" md="8" style="float:none;margin:0 auto">
          <b-row>
            <b-col cols="12" sm="12" md="4" style="text-align:center;max-width:300px;margin-left:auto;margin-right:auto;margin-bottom:10px;">
              <span style="margin-bottom:30px;display:block;">Te rugăm să încarci bugetul proiectului <strong>POCU {{currentProject.id_proiect}}</strong> exportat din MySMIS</span>
              <pocu-file-upload-button
                button-text="Încarcă"
                @fileChanged="bugetFile=$event"
              />
            </b-col>
            <b-col cols="0" sm="0" md="4">
            </b-col>
            <b-col cols="12" sm="12" md="4" style="text-align:center;max-width:300px;margin-left:auto;margin-right:auto;margin-bottom:10px;">
              <span style="margin-bottom:30px;display:block;">Te rugăm să încarci autorizarea cererilor de rambursare aferente proiectului <strong>POCU {{currentProject.id_proiect}}</strong></span>
              <pocu-file-upload-button
                button-text="Încarcă"
                @fileChanged="cheltuieliFile=$event"
              />
            </b-col>
          </b-row>
          <b-row style="margin-top:30px;">
            <b-col cols="3" style="float:none;margin:0 auto">
              <b-button
                pill
                variant="primary"
                :disabled="!(bugetFile)"
                @click="onStartImportClick"
              >
                <span v-if="isUploading">
                  <b-spinner  small></b-spinner>
                  <span class="align-middle" style="margin-left:5px;">În procesare</span>
                </span>
                <span v-else>
                  <span class="align-middle" style="margin-left:5px;">Start Import</span>
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div v-else>
        <div id="no_content">
          <p>Nu ai nici un proiect. Poți adăuga un proiect nou folosind butonul de mai sus.</p>
        </div>
      </div>
      <b-modal
        id="modal-upload-error"
        title="Eroare la încărcare"
        ok-only
        ok-title="OK"
        centered
        v-model="uploadErrorModalVisible"
        @hidden="resetUploadErrorModal"
        >
        <p>{{uploadErrorText}}</p>
      </b-modal>
    </b-card>

    <b-card class="text-center">
      <div class="infos">
        <b-img
              class="warning mb-2"
              width="80"
              center
              :src="require('@/assets/images/m2/warning.png')"
        />
        <h4 class="mb-1"><strong>INFORMARE</strong></h4>
        După selectarea celor două documente de proiect și apăsarea butonului START IMPORT - veți fi direcționat automat către bugetul de proiect și autorizări.
        <br>Acest proces <strong>durează aproximativ 10-20 secunde</strong>. În cazul în care nu sunteți transferat către secțiunea de buget, cel mai probabil documentele încarcate nu sunt compatibile (din diverse motive)
        <h5 class="mt-1 mb-05">În acest caz vă rugăm să ne contactați la:</h5>
        <a href="tel:0756015520">0756.01.55.20</a><br>
        <a href="mailto:office@smis.ro">office@smis.ro</a>
        <h5 class="mt-1 mb-2">Vom prelua și adapta documentele dumneavoastră.</h5>
        <h5 class="mt-1 mb-2">Vă invităm să descărcați mai jos <span>modele a documentelor</span> ce urmează a fi încărcate.</h5>
        <b-button variant="outline-primary mr-2 mb-1">
          <a download href="/pocu/BUGET_DEMO.xlsx">Descarcă DEMO buget</a>
        </b-button>
        <b-button variant="outline-primary mb-1">
          <a download href="/pocu/AUTORIZARI_DEMO.xlsx">Descarcă DEMO autorizări</a>
        </b-button>
      </div>
    </b-card>

  </div>
</template>

<script>
import axios from '@axios'
import {
  BSpinner, BImg, BRow, BCol, BCard, BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BFormCheckboxGroup, BIconNutFill, BModal, VBModal
} from 'bootstrap-vue'
import { UploadCloudIcon } from 'vue-feather-icons'
import PocuFileUploadButton from './parts/PocuFileUploadButton.vue'
import PocuHeader from './parts/PocuHeader.vue'
import {mapState} from 'vuex'

export default {
  components:{
    BRow,
    BCol,
    BCard,
    BButton,
    BCardBody,
    UploadCloudIcon,
    PocuFileUploadButton,
    BSpinner,
    PocuHeader,
    BModal,
    VBModal,
    BImg
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      bugetFile: null,
      cheltuieliFile: null,
      isUploading: false,
      uploadErrorModalVisible : false,
      uploadErrorText : '',
    }
  },
  computed:{
    ...mapState('pocu',{
      currentProjectId : 'project_id',
      allProjects: 'projects'
    }),
    currentProject(){
      return this.allProjects[this.currentProjectId]
    },
  },
  methods: {
    resetUploadForm(){
      this.isUploading = false
    },
    showUploadErrorModal(message){
      this.uploadErrorText = message
      this.uploadErrorModalVisible = true
    },
    resetUploadErrorModal(){
      this.uploadErrorModalVisible = false
      this.uploadErrorText = ''
    },
    onFileChangeBuget(event) {
      this.bugetFile = event.target.files ? event.target.files[0] : null
    },
    onFileChangeCheltuieli(event) {
      this.cheltuieliFile = event.target.files ? event.target.files[0] : null
    },
    onStartImportClick(event) {
      this.isUploading = true
      const config = {
        headers: { 'content-type': 'multipart/form-data'}
      }
      let formData = new FormData()
      formData.append('excel_buget',this.bugetFile)
      if (this.cheltuieliFile){
        formData.append('excel_cheltuieli',this.cheltuieliFile)
      }
      formData.append('id_proiect',this.currentProjectId)
      const $this = this
      axios.post('/pocu/uploadBugetExcel', formData, config)
        .then(function(response){
          if (response.data.success){
            $this.$router.push({name:'pocu-prezentare-buget'})
          } else {
            $this.showUploadErrorModal(response.data.error_message)
            //Reset form
            $this.resetUploadForm()
          }
          
        })
        .catch(function(error){
          console.log(error)
        })
    },
  }
}
</script>

<style>
#no_content{
  text-align:center;
}
.infos span{
  color: #01e8c6;
}
</style>