<template>
  <div>
    <b-button
      pill
      variant="primary"
      @click="onButtonClick"
    >
      <span v-if="isSelecting">
        <b-spinner  small></b-spinner>
      </span>
      <span v-else>
        <upload-cloud-icon size="1.5x" class="custom-class"></upload-cloud-icon>
        <span class="align-middle" style="margin-left:5px;">{{ buttonTextValue }}</span>
      </span>
    </b-button>
    <input
      ref="uploader"
      class="d-none"
      type="file"
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      @change="onFileChanged"
    >
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BFormCheckboxGroup, BIconNutFill, BSpinner
} from 'bootstrap-vue'
import { UploadCloudIcon } from 'vue-feather-icons'

export default {
  components : {
    BButton,
    BSpinner,
    UploadCloudIcon
  },
  props:['buttonText'],
  data(){
    return {
      // defaultButtonText: 'Încarcă',
      selectedFile: null,
      isSelecting: false
    }
  },
  computed:{
    buttonTextValue(){
      return this.selectedFile ? this.selectedFile.name : this.buttonText
    }
  },
  methods:{
    onButtonClick(){
      this.isSelecting = true
      window.addEventListener('focus',()=>{
        this.isSelecting = false
      }, {once:true})
      this.$refs.uploader.click()
    },
    onFileChanged(e){
      this.selectedFile = e.target.files[0]
      this.$emit('fileChanged',this.selectedFile)
    },
  },
  
}
</script>

<style>
.v-icon--left {
  margin-right: 8px;
}
</style>